import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'
import Layout from '@/components/Layout'

export async function getStaticProps({ preview }: GetStaticPropsContext) {
  return {
    props: {},
  }
}

// TODO: Use a better 500 page from tailwindui
const NotFound: React.FC<
  InferGetStaticPropsType<typeof getStaticProps>
> = () => {
  return (
    <>
      <Layout showLiveChatWidget>
        <div className={'container'}>
          <div className="flex flex-col items-center justify-center max-w-2xl py-20 mx-8 sm:mx-auto fit">
            <h2 className={'font-bold text-6xl mb-4'}>Server Error</h2>
            <div className="">
              Something bad happened on our end, contact support if it keeps
              happening.
            </div>
          </div>
        </div>
      </Layout>
      <NextSeo title={'SERVER ERROR'} />
    </>
  )
}

export default NotFound
